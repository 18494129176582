export function fetchPost(endpoint, params, callback) {
    fetch('https://' + localStorage.getItem('host') + endpoint,
        {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "JWT " + localStorage.getItem("access")
                },
                body: JSON.stringify(params)
        })
            .then(response => response.json())
            .then(data => {
                callback(data)
            })
}

export function fetchGet(endpoint, params, callback) {
    fetch('https://' + localStorage.getItem('host') + endpoint + '?' + new URLSearchParams(params),
        {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "JWT " + localStorage.getItem("access")
            }})
            .then(response => response.json())
            .then(data => {
                callback(data)
            })
}

// to upload the picture along with the POST 
export function uploadData(endpoint, params, callback) {
    
    fetch('https://' + localStorage.getItem('host') + endpoint,
        {
                method: "POST",
                headers: {
                    // "Content-Type": "application/json",
                    "Authorization": "JWT " + localStorage.getItem("access")
                },
                body: params
        })
            .then(response => response.json())
            .then(data => {
                callback(data)
            })
}