import React, { useState, useEffect } from 'react';
import { SideBar } from '../SideBar';
import { NavBar } from '../NavBar';
import { ParkNow } from './ParkNow';
import { ActiveCard } from './ActiveCard';
import { fetchGet } from '../request';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './active.css';

const checkInAdvance = 15 * 60 * 1000; // 15 minutes in milliseconds

const formatNum = (num) => (num < 10 ? '0' + num : num);

export function ActiveHome() {
    const [showModal, setShowModal] = useState(false);
    const [hasActiveSession, setHasActiveSession] = useState(false);
    const [activeTrans, setActiveTrans] = useState([]);
    const [didUpdate, setDidUpdate] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const cardsPerPage = 1;

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => {
        fetchData();
        setShowModal(false);
    };

    const updateListCallback = (data) => {
        let activeTrans = [];
        const now = Date.now(); // Get the current time once
        for (let i = 0; i < data.length; i++) {
            let record = {};
            let checkInTime = new Date(data[i]['checkInTime']);
            let scheduledLeaveTime = new Date(data[i]['scheduledLeaveTime']);
            let checkOutTime = null;
            if (data[i]['isCompleted'] === true) {
                checkOutTime = new Date(data[i]['checkOutTime']);
            }
            record['id'] = data[i]['id'];
            record['date'] = checkInTime.getMonth() + 1 + "/" + checkInTime.getDate() + "/" + checkInTime.getFullYear();
            record['startTime'] = formatNum(checkInTime.getHours()) + ":" + formatNum(checkInTime.getMinutes());
            if (checkOutTime != null) {
                record['endTime'] = formatNum(checkOutTime.getHours()) + ":" + formatNum(checkOutTime.getMinutes());
            } else {
                record['endTime'] = formatNum(scheduledLeaveTime.getHours()) + ":" + formatNum(scheduledLeaveTime.getMinutes());
            }
            record['parkingLot'] = data[i]['parkingLot'];
            record['spot'] = data[i]['spot'];
            record['checkInTime'] = checkInTime;
            record['scheduledLeaveTime'] = scheduledLeaveTime;
            record['checkOutTime'] = checkOutTime;
            record['charge'] = data[i]['charge'];
            record['reservationFee'] = data[i]['reservationFee'];
            record['isCompleted'] = data[i]['isCompleted'];
            record['isCanceled'] = data[i]['isCanceled'];

            if (checkInTime < now && !data[i]['isCompleted'] && !data[i]['isCanceled'] && scheduledLeaveTime > now) {
                activeTrans.push(record);
            }
        }
        setActiveTrans(activeTrans);
        setHasActiveSession(activeTrans.length > 0);
        setDidUpdate(false); // Reset didUpdate to false after data is fetched
    };

    const fetchData = () => {
        fetchGet('/api/reservation/', {}, updateListCallback);
    };

    useEffect(() => {
        fetchData();
        const intervalId = setInterval(fetchData, 60000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        if (didUpdate) {
            fetchData();
        }
    }, [didUpdate]);

    const indexOfLastCard = currentPage * cardsPerPage;
    const indexOfFirstCard = indexOfLastCard - cardsPerPage;
    const currentCards = activeTrans.slice(indexOfFirstCard, indexOfLastCard);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const updateHandlerProp = ()=>{
        // alert("Update handler called ");
        setDidUpdate(true)
    }
    return (
        <Container fluid>
            <Row>
                <Col md={3}>
                    <SideBar username={localStorage.getItem('username')} />
                </Col>
                <Col md={8} className="p-3">
                    <NavBar />
                    {!hasActiveSession ? (
                        <div className="d-flex align-items-center justify-content-center" style={{ height: 'calc(100vh - 56px)' }}>
                            <div className="text-center">
                                <h3 style={{ fontWeight: 'bold' }}>No Active Parking Sessions Right Now</h3>
                                <Button onClick={handleShowModal} className="btn btn-primary park-now">Park Now</Button>
                                <ParkNow show={showModal} walkIn={true} handleClose={handleCloseModal} />
                            </div>
                        </div>
                    ) : (
                        <div className="active-trans-container">
                            {currentCards.map((item, index) => (
                                <ActiveCard
                                    key={'active' + index}
                                    id={item['id']}
                                    startTime={item['startTime']}
                                    endTime={item['endTime']}
                                    checkInTime={item['checkInTime']}
                                    scheduledLeaveTime={item['scheduledLeaveTime']}
                                    date={item['date']}
                                    parkingLot={item['parkingLot']}
                                    spot={item['spot']}
                                    isCanceled={item['isCanceled']}
                                    isCompleted={item['isCompleted']}
                                    updateHandle={updateHandlerProp}
                                    countdown={item['scheduledLeaveTime'] - new Date()}
                                />
                            ))}
                            <div className="card-footer d-flex justify-content-between">
                                <Button className="btn btn-secondary-sm" onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>{"<"}</Button>
                                <Button className="btn btn-secondary-sm" onClick={() => paginate(currentPage + 1)} disabled={currentPage === Math.ceil(activeTrans.length / cardsPerPage)}>{">"}</Button>
                            </div>
                        </div>
                    )}
                </Col>
            </Row>
        </Container>
    );
}

export default ActiveHome;
