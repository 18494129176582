import React, { useEffect, useState } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Container, Row, Col, Image } from 'react-bootstrap';
import ParkingLogo from '../images/ParkingLogo.png';
import './sidebar.css'

function UserDropdown({ username, handleLogout }) {
    const history = useHistory();

    const handleRedirect = (link) => {
        return function (event) {
            event.preventDefault();
            history.push(link);
        };
    };

    return (
        <NavDropdown title={username} id="user-dropdown">
            <NavDropdown.Item onClick={handleRedirect("/profile")}>Profile</NavDropdown.Item>
            <NavDropdown.Item onClick={handleRedirect("/payment")}>Payment</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
        </NavDropdown>
    );
}

function UserButton({ username, handleLogout }) {
    const history = useHistory();

    const handleSignIn = (event) => {
        event.preventDefault();
        history.push('/signin');
    };

    return (
        <>
            {username ? (
                <UserDropdown username={username} handleLogout={handleLogout} />
            ) : (
                <Nav.Link onClick={handleSignIn}>Sign in</Nav.Link>
            )}
        </>
    );
}

export function SideBar({ username }) {
    const [hasChecked, setHasChecked] = useState(false);
    const history = useHistory();
    const [userRole, setUserRole] = useState(localStorage.getItem('userRole'));
    const handleLogout = (event) => {
        event.preventDefault();
        localStorage.removeItem('username');
        localStorage.removeItem('access');
        localStorage.removeItem('refresh');
        history.push('/signin');
        window.location.reload();
    };

    const checkSession = () => {
        fetch('http://' + localStorage.getItem('host') + '/api/hello', {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "JWT " + localStorage.getItem("access")
            }
        }).then(response => {
            if (response.status === 401) {
                refreshToken();
            }
        });
    };

    const refreshToken = () => {
        const info = {
            "username": localStorage.getItem("username"),
            "refresh": localStorage.getItem("refresh")
        };
        fetch('http://' + localStorage.getItem('host') + '/api/token/refresh', {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(info)
        }).then(response => {
            if (response.status === 401) {
                forceLogout();
                return null;
            } else {
                return response.json();
            }
        }).then(data => {
            if (data != null) {
                localStorage.setItem("access", data["access"]);
                localStorage.setItem("refresh", data["refresh"]);
            }
        });
    };

    const forceLogout = () => {
        if (localStorage.getItem('username') !== null) {
            localStorage.clear();
            alert("Your login session expired. Please sign in again.");
            history.push("/signin");
        }
    };

    useEffect(() => {
        if (!hasChecked) {
            checkSession();
            setInterval(checkSession, 10000);
        }
        setHasChecked(true);
    }, [hasChecked]);

    return (
        <Navbar bg="light" expand="lg" className="flex-column align-items-start sidebar-nav">
            <Container className="flex-column align-items-start">
                <Row className="w-100 mb-4">
                    <Col className="text-center">
                        <Navbar.Brand className="d-flex flex-column align-items-center">
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <Image 
                                    src={ParkingLogo} 
                                    alt="Tartan Parking Logo" 
                                    style={{ width: '10vw', maxWidth: '20px'}} 
                                    className='mr-2'
                                />
                                <strong>Tartan Parking</strong>
                            </div>
                            
                        </Navbar.Brand>
                    </Col>
                </Row>
                <Row className="w-100">
                    <Col>
                    <Nav className="flex-column w-100">
                        {/* Manager Section */}
                        {userRole == 1 && (<Nav.Item>
                            <strong>Manager Control</strong>
                            {userRole == 1 && (
                            <Nav.Link as={NavLink} exact to="/managerreport" activeClassName="active-link">
                                <i className="bi bi-clipboard-data"></i> Manager Report
                            </Nav.Link>)}
                            {userRole == 1 &&
                            (<Nav.Link as={NavLink} exact to="/generatecoupan" activeClassName="active-link">
                            <i className="bi bi-cash-stack"></i> Generate Coupon
                            </Nav.Link>)}
                            {userRole == 1 &&
                            (<Nav.Link as={NavLink} exact to="/releasespot" activeClassName="active-link">
                            <i className="bi bi-cash-stack"></i> Release Spots
                            </Nav.Link>)}
                        </Nav.Item>)}

                        {/* Reservation Section */}
                        <Nav.Item>
                            <strong>Reservation</strong>
                            <Nav.Link as={NavLink} exact to="/activehome" activeClassName="active-link">
                                <i className="bi bi-clipboard-data"></i> Active
                            </Nav.Link>
                            <Nav.Link as={NavLink} exact to="/upcominghome" activeClassName="active-link">
                            <i className="bi bi-cash-stack"></i> Upcoming
                            </Nav.Link>
                            <Nav.Link as={NavLink} exact to="/historyhome" activeClassName="active-link">
                                <i className="bi bi-house-door"></i> History
                            </Nav.Link>
                        </Nav.Item>

                        {/* Report Section */}
                        <Nav.Item>
                            <strong>Report</strong>
                            <Nav.Link as={NavLink} exact to="/report" activeClassName="active-link">
                                <i className="bi bi-exclamation-diamond"></i> Report Now
                            </Nav.Link>
                            <Nav.Link as={NavLink} exact to="/reporthistory" activeClassName="active-link">
                                <i className="bi bi-exclamation-diamond"></i> History
                            </Nav.Link>
                        </Nav.Item>

                        {/* Profile Section */}
                        <Nav.Item>
                            <strong>Profile</strong>
                            <Nav.Link as={NavLink} exact to="/profile" activeClassName="active-link">
                                <i className="bi bi-person-circle"></i> Profile
                            </Nav.Link>
                        </Nav.Item>

                        {/* Payment Section */}
                        <Nav.Item>
                            <strong>Payment</strong>
                            <Nav.Link as={NavLink} exact to="/payment" activeClassName="active-link">
                                <i className="bi bi-credit-card"></i> Payment
                            </Nav.Link>
                        </Nav.Item>

                        {/* Logout */}
                        <Nav.Item>
                            <strong>{username}</strong>
                            <Nav.Link as={NavLink} exact to="/logout" activeClassName="active-link">
                                <i className="bi bi-box-arrow-right"></i> Logout
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                    </Col>
                </Row>
                {/* <Row className="w-100 mt-auto">
                    <Col>
                        <Nav className="ml-auto">
                            <UserButton username={username} handleLogout={handleLogout} />
                        </Nav>
                    </Col>
                </Row> */}
            </Container>
        </Navbar>
    );
}


// import React, { useEffect, useState } from 'react';
// import { useHistory, NavLink } from 'react-router-dom';
// import { Navbar, Nav, NavDropdown, Container, Row, Col } from 'react-bootstrap';
// import ParkingLogo from '../images/ParkingLogo.png';

// function UserDropdown({ username, handleLogout }) {
//     const history = useHistory();

//     const handleRedirect = (link) => {
//         return function (event) {
//             event.preventDefault();
//             history.push(link);
//         };
//     };

//     return (
//         <NavDropdown title={username} id="user-dropdown">
//             <NavDropdown.Item onClick={handleRedirect("/profile")}>Profile</NavDropdown.Item>
//             <NavDropdown.Item onClick={handleRedirect("/payment")}>Payment</NavDropdown.Item>
//             <NavDropdown.Divider />
//             <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
//         </NavDropdown>
//     );
// }

// function UserButton({ username, handleLogout }) {
//     const history = useHistory();

//     const handleSignIn = (event) => {
//         event.preventDefault();
//         history.push('/signin');
//     };

//     return (
//         <>
//             {username ? (
//                 <UserDropdown username={username} handleLogout={handleLogout} />
//             ) : (
//                 <Nav.Link onClick={handleSignIn}>Sign in</Nav.Link>
//             )}
//         </>
//     );
// }

// export function SideBar({ username }) {
//     const [hasChecked, setHasChecked] = useState(false);
//     const history = useHistory();

//     const handleLogout = (event) => {
//         event.preventDefault();
//         localStorage.removeItem('username');
//         localStorage.removeItem('access');
//         localStorage.removeItem('refresh');
//         history.push('/signin');
//         window.location.reload();
//     };

//     const checkSession = () => {
//         fetch('http://' + localStorage.getItem('host') + '/api/hello', {
//             method: "GET",
//             headers: {
//                 "Content-Type": "application/json",
//                 "Authorization": "JWT " + localStorage.getItem("access")
//             }
//         }).then(response => {
//             if (response.status === 401) {
//                 refreshToken();
//             }
//         });
//     };

//     const refreshToken = () => {
//         const info = {
//             "username": localStorage.getItem("username"),
//             "refresh": localStorage.getItem("refresh")
//         };
//         fetch('http://' + localStorage.getItem('host') + '/api/token/refresh', {
//             method: "POST",
//             headers: {
//                 "Content-Type": "application/json"
//             },
//             body: JSON.stringify(info)
//         }).then(response => {
//             if (response.status === 401) {
//                 forceLogout();
//                 return null;
//             } else {
//                 return response.json();
//             }
//         }).then(data => {
//             if (data != null) {
//                 localStorage.setItem("access", data["access"]);
//                 localStorage.setItem("refresh", data["refresh"]);
//             }
//         });
//     };

//     const forceLogout = () => {
//         if (localStorage.getItem('username') !== null) {
//             localStorage.clear();
//             alert("Your login session expired. Please sign in again.");
//             history.push("/signin");
//         }
//     };

//     useEffect(() => {
//         if (!hasChecked) {
//             checkSession();
//             setInterval(checkSession, 10000);
//         }
//         setHasChecked(true);
//     }, [hasChecked]);

//     return (
//         <Navbar bg="light" expand="lg" className="flex-column align-items-start">
//             <Container className="flex-column">
//                 <Row className="w-100 mb-4">
//                     <Col className="text-center">
//                         <Navbar.Brand>
//                             <img 
//                                 src={ParkingLogo} 
//                                 alt="Tartan Parking Logo" 
//                                 style={{ width: '10vw', maxWidth: '100px', height: 'auto' }} 
//                             />
//                             <div><strong>Tartan Parking</strong></div>
//                         </Navbar.Brand>
//                     </Col>
//                 </Row>
//                 <Row className="w-100">
//                     <Col>
//                         <Nav className="flex-column w-100">
//                             <Nav.Link as={NavLink} exact to="/activehome">Home</Nav.Link>
//                             <Nav.Link as={NavLink} exact to="/report">Report</Nav.Link>
//                             <Nav.Link as={NavLink} exact to="/notifications">Notifications</Nav.Link>
//                             <Nav.Link as={NavLink} exact to="/profile">Profile</Nav.Link>
//                             <Nav.Link as={NavLink} exact to="/managerreport">Manage Reports</Nav.Link>
//                         </Nav>
//                     </Col>
//                 </Row>
//                 <Row className="w-100 mt-auto">
//                     <Col>
//                         <Nav className="ml-auto">
//                             <UserButton username={username} handleLogout={handleLogout} />
//                         </Nav>
//                     </Col>
//                 </Row>
//             </Container>
//         </Navbar>
//     );
// }



// import React, {useEffect, useState} from 'react'
// import {useHistory} from 'react-router-dom'
// import './sidebar.css'
// import ParkingLogo from '../images/ParkingLogo.png'
// import { NavLink } from 'react-router-dom';

// // Dropdown menu when user logged in
// function UserDropdown(props) {
//     const history = useHistory()

//     const handleRedirect = (link) => {
//         return function (event) {
//             history.push(link)
//         }
//     }

//     return <li className="nav-item dropdown">
//         <a className='nav-link dropdown-toggle' data-toggle='dropdown'
//                 aria-haspopup='true' aria-expanded='false'>
//                 <span>{props.username}</span>
//         </a>
//         <div className='dropdown-menu'>
//             <a className='dropdown-item' onClick={handleRedirect("/profile")}>
//                 <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" fill="currentColor"
//                      className="bi bi-file-person-fill" viewBox="0 0 16 16">
//                     <path
//                         d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm-1 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0zm-3 4c2.623 0 4.146.826 5 1.755V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-1.245C3.854 11.825 5.377 11 8 11z"/>
//                 </svg>
//                 &nbsp;
//                 Profile</a>
//             <a className='dropdown-item' onClick={handleRedirect("/payment")}>
//                 <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" fill="currentColor"
//                      className="bi bi-wallet2" viewBox="0 0 16 16">
//                     <path
//                         d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499L12.136.326zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484L5.562 3zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z"/>
//                 </svg>
//                 &nbsp;
//                 Payment</a>
//             <div className='dropdown-divider'></div>
//             <a className='dropdown-item' onClick={props.handleLogout}>Logout</a>
//         </div>
//     </li>
// }

// // Wrapper of the user button
// function UserButton(props) {
//     const [username, setUsername] = useState(props.username)
//     const history = useHistory()

//     const handleLogout = (event) => {
//         event.preventDefault()
//         // remove all account related data
//         localStorage.removeItem('username')
//         localStorage.removeItem('access')
//         localStorage.removeItem('refresh')

//         setUsername(null)
//         history.push('/')
//         window.location.reload()
//     }

//     return  <div>
//     {username == null
//         ?   <li className="nav-item">
//             <a className="nav-link" href="/signin">Sign in</a>
//         </li>
//         :   <UserDropdown username={username} handleLogout={handleLogout}/>
//     }
//     </div>
// }

// // The main navigation bar component
// export function SideBar(props) {
//     const [hasChecked, setHasChecked] = useState(false)
//     const [username, setUsername] = useState(props.username)
//     const history = useHistory()

//     const handleLogout = () => {
//         localStorage.removeItem('username')
//         localStorage.removeItem('access')
//         localStorage.removeItem('refresh')

//         setUsername(null)
//         history.push('/signin')
//     }

//     const checkSession = () => {    // check whether access token has expired
//         fetch('http://' + localStorage.getItem('host') + '/api/hello', {
//             method: "GET",
//             headers: {
//                 "Content-Type": "application/json",
//                 "Authorization": "JWT " + localStorage.getItem("access")
//             }
//         })
//             .then(response => {
//                 if (response.status === 401) {
//                     refreshToken()
//                 }
//             })
//     }

//     const refreshToken = () => {    // attempt to refresh the access token
//         const  info = {
//                 "username": localStorage.getItem("username"),
//                 "refresh": localStorage.getItem("refresh")
//             }
//         fetch('http://' + localStorage.getItem('host') + '/api/token/refresh', {
//             method: "POST",
//             headers: {
//                 "Content-Type": "application/json"
//             },
//             body: JSON.stringify(info)
//         })
//         .then(response => {
//             if (response.status === 401) {
//                 forceLogout()
//                 return null
//             } else {
//                 return response.json()
//             }
//         })
//         .then(data => {
//             if (data != null) {
//                 localStorage.setItem("access", data["access"])
//                 localStorage.setItem("refresh", data["refresh"])
//             }
//         })
//     }

//     const forceLogout = () => { // logout when the refresh token expired
//         if (localStorage.getItem('username') !== null) {    // only pop for once
//             localStorage.clear()
//             alert("Your login session expired. Please sign in again.")
//             history.push("/signin")
//         }
//     }

//     useEffect(() => {
//         if (!hasChecked) {
//             checkSession()
//             setInterval(checkSession, 10000)
//         }
//         setHasChecked(true)
//     })

//     const homeIsActive = (match, location) => {
//         const paths = ['/activehome', '/upcominghome', '/historyhome'];
//         return paths.some(path => location.pathname === path);
//     };


//     return (
//     <div className="sidebar-nav">
//         <div className="navbar-title">
//                 <img src={ParkingLogo} alt="Tartan Parking Logo" className="logo-nav" />
//                 <h4><strong>Tartan Parking</strong></h4>
//         </div>
//       <ul className="nav flex-column">
//         <li className="nav-item">
//             <NavLink exact to="/activehome" className="nav-link" isActive={homeIsActive}>
//             <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door" viewBox="0 0 16 16">
//               <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354l-6.5-6.5z"/>
//               <path d="M13 2.5v3l2 2v-5z"/>
//             </svg>
//              &nbsp;  Home
//           </NavLink>
//         </li>
//         <li className="nav-item">
//           <NavLink exact to="/report" className="nav-link" activeClassName="active">
//             <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-diamond" viewBox="0 0 16 16">
//                 <path d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.48 1.48 0 0 1 0-2.098zm1.4.7a.495.495 0 0 0-.7 0L1.134 7.65a.495.495 0 0 0 0 .7l6.516 6.516a.495.495 0 0 0 .7 0l6.516-6.516a.495.495 0 0 0 0-.7L8.35 1.134z"/>
//                 <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/>
//             </svg>
//               &nbsp;  Report
//             </NavLink>
//         </li>
//         <li className="nav-item">
//           <NavLink exact to="/notifications" className="nav-link" activeClassName="active">
//             <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bell" viewBox="0 0 16 16">
//                 <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2M8 1.918l-.797.161A4 4 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4 4 0 0 0-3.203-3.92zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5 5 0 0 1 13 6c0 .88.32 4.2 1.22 6"/>
//             </svg>
//               &nbsp;  Notifications
//             </NavLink>
//         </li>
//         <li className="nav-item">
//           <NavLink exact to="/profile" className="nav-link" activeClassName="active">
//             <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
//                 <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
//                 <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
//             </svg>
//               &nbsp;  Profile
//             </NavLink>
//         </li>
//       </ul>
//       <NavLink to="/logout" className="logout-link" activeClassName="active" onClick={handleLogout}>
//             <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-left" viewBox="0 0 16 16">
//             <path fill-rule="evenodd" d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0z"/>
//             <path fill-rule="evenodd" d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708z"/>
//             </svg>
//                &nbsp;  Logout
//         </NavLink>
//       <div className="nav-user-info d-flex align-items-center justify-content-start">
//         <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
//             <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
//             <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
//         </svg>
//         <span className="nav-user-name">{props.username}</span>
//     </div>

//     </div>
//   );
// }