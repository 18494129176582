import React, { useEffect, useState } from 'react';
import { fetchPost } from '../request';
import { Modal, Button, Image, Container, Row, Col, Form } from 'react-bootstrap';
import './reportReview.css';

const ReportReview = ({ reportId, onClose, onUpdate }) => {
    const [report, setReport] = useState(null);
    const [action, setAction] = useState('No Action');
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [isPlateConfirmed, setIsPlateConfirmed] = useState(false); // New state for plate confirmation
    const [editablePlate, setEditablePlate] = useState(''); // New state for editable plate number
    const [isEditingPlate, setIsEditingPlate] = useState(false); // New state to track if the plate is being edited
    const baseURL = 'http://' + localStorage.getItem('host'); // Get base URL from localStorage

    useEffect(() => {
        fetchReportDetails();
    }, []);

    const fetchReportDetails = () => {
        fetchPost('/api/report/details/', { report_id: reportId }, (data) => {
            if (data.success) {
                setReport(data.report);
                setEditablePlate(data.report.plateNumber || ''); // Initialize editable plate number
            }
        });
    }
    const markAsValid = () => {
        fetchPost('/api/report/isValid_volunteer/', { report_id: reportId, isValid: true, time: Date.now() , spot: report.spot}, (response) => {
            console.log(response);
            if (response.success) {
                onUpdate();
                fetchReportDetails(); // Refresh the report details
            }
        });
    }
    const markAsInValid = () => {
        fetchPost('/api/report/update/', { report_id: reportId, isValid: false, isReviewed: true, isSolved: true, actionTaken: 'InValid Report No Action Taken' }, (response) => {
            if (response.success) {
                onUpdate();
                fetchReportDetails(); // Refresh the report details
            }
        });
    }
    const markAsReviewed = () => {
        fetchPost('/api/report/update/', { report_id: reportId, isReviewed: true, plate: editablePlate }, (response) => {
            if (response.success) {
                onUpdate();
                fetchReportDetails(); // Refresh the report details
            }
        });
    }

    const markAsSolved = () => {
        fetchPost('/api/report/update/', { report_id: reportId, isSolved: true, actionTaken: action }, (response) => {
            if (response.success) {
                onUpdate();
                fetchReportDetails(); // Refresh the report details
            }
        });
    }

    const issueReward = () => {
        fetchPost('/api/report/issue_reward/', { report_id: reportId }, (response) => {
            if (response.success) {
                onUpdate();
                fetchReportDetails(); // Refresh the report details
            } else {
                alert(response.error);
            }
        });
    }

    const toggleFullScreen = () => {
        setIsFullScreen(!isFullScreen);
    }

    const handleEditPlateClick = () => {
        setIsEditingPlate(true);
    }

    const handleSavePlateClick = () => {
        setIsEditingPlate(false);
        setIsPlateConfirmed(false); // Require confirmation again after editing
    }

    if (!report) {
        return <div>Loading...</div>;
    }

    const isMarkAsReviewedDisabled = !isPlateConfirmed || editablePlate.trim() === ''; // Disable if plate not confirmed or empty

    return (
        <>
            <Modal show={!isFullScreen} onHide={onClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Review Report ID: {report.id}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col>
                                <Image
                                    src={`${baseURL}${report.pic}`}
                                    alt="Report"
                                    fluid
                                    thumbnail
                                    onClick={toggleFullScreen}
                                    className="report-image"
                                    style={{ cursor: 'pointer', maxHeight: '300px' }}
                                />
                            </Col>
                        </Row>
                    </Container>
                    <Container>
                        <Row className="mt-3">
                            <Col>
                                <p>Parking Lot: {report.parkingLot}</p>
                                <p>Spot: {report.spot}</p>
                                <p>
                                    Plate: 
                                    {isEditingPlate ? (
                                        <Form.Control
                                            type="text"
                                            value={editablePlate}
                                            onChange={(e) => setEditablePlate(e.target.value)}
                                            placeholder="Enter Plate Number"
                                            className="d-inline-block w-auto ml-2"
                                            required
                                        />
                                    ) : (
                                        <span> {editablePlate} </span>
                                    )}
                                </p>
                                <p>Time: {new Date(report.time).toLocaleString()}</p>
                                <p>Status: {report.isReviewed ? 'Reviewed' : 'Pending'}</p>
                                <p>Action Taken: {report.actionTaken || 'No Action'}</p>
                                <p>Valid Report : {report.isValid  ? 'True' : 'False'}</p>
                                {report.isValid && !report.isSolved && !report.isReviewed && (
                                    <>
                                    {!isEditingPlate ? (
                                        <Button variant="warning" onClick={handleEditPlateClick}>
                                            Edit Plate Number
                                        </Button>
                                    ) : (
                                        <Button variant="success" onClick={handleSavePlateClick} className="ml-2">
                                            Save Plate Number
                                        </Button>
                                    )}

                                    <Form.Check 
                                        type="checkbox" 
                                        label="Confirm Plate Number" 
                                        checked={isPlateConfirmed} 
                                        onChange={(e) => setIsPlateConfirmed(e.target.checked)} 
                                        className="mt-3"
                                        disabled={isEditingPlate} // Disable confirmation while editing
                                    />
                                </>
                            )}

                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    {report.isValid === false && report.isSolved === false ? (
                        <>
                            <Button variant="success" onClick={markAsValid}>
                                Mark as Valid
                            </Button>
                            <Button variant="danger" onClick={markAsInValid}>
                                Mark as Invalid
                            </Button>
                        </>
                    ) : (
                        <>
                            {!report.isReviewed && (
                                <Button 
                                    variant="primary" 
                                    onClick={() => markAsReviewed()} 
                                    disabled={!isPlateConfirmed || editablePlate.trim() === ''}
                                >
                                    Mark as Reviewed
                                </Button>
                            )}
                            {report.isReviewed && !report.isSolved && (
                                <>
                                    <Row>
                                        <Button variant="success" onClick={() => markAsSolved()}>
                                            Mark as Solved
                                        </Button>
                                    </Row>
                                    <Row>
                                        <select
                                            className="form-control mt-2"
                                            value={action}
                                            onChange={(e) => setAction(e.target.value)}
                                        >
                                            <option>No Action</option>
                                            <option>Warning</option>
                                            <option>Fined</option>
                                            <option>Towed Away</option>
                                        </select>
                                    </Row>  
                                </>
                            )}
                            {report.isReviewed && report.isSolved && !report.isReceived && report.isValid && (
                                <Button variant="info" onClick={() => issueReward()}>
                                    Issue Reward
                                </Button>
                            )}
                        </>
                    )}
                    <Button variant="secondary" onClick={onClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {isFullScreen && (
                <div className="fullscreen">
                    <Image
                        src={`${baseURL}${report.pic}`}
                        alt="Report"
                        className="fullscreen-image"
                        onClick={toggleFullScreen}
                    />
                    <button className="close-btn" onClick={toggleFullScreen}>
                        Close Full Screen
                    </button>
                </div>
            )}
        </>
    );
};

export default ReportReview;
