import React, { useEffect, useState } from 'react';
import { SideBar } from "../SideBar";
import './reportManager.css';
import { fetchGet, fetchPost } from '../request';
import ReportReview from './ReportReview';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Container, Row, Col, Button, Card, Tab, Tabs } from 'react-bootstrap';

export function ReportManager(props) {
    const [reports, setReports] = useState([]);
    const [didUpdate, setDidUpdate] = useState(false);
    const [userInfo, setUserInfo] = useState({});
    const [selectedTab, setSelectedTab] = useState('today');
    const [selectedReport, setSelectedReport] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const recordsPerPage = 10;

    useEffect(() => {
        if (!didUpdate) {
            fetchGet('/api/account/profile', {}, updateCallback);
        }
    }, [didUpdate]);

    const updateCallback = (data) => {
        setUserInfo(data);
        fetchReports();
        setDidUpdate(true);
    }

    const fetchReports = () => {
        fetchGet('/api/report/reports', {}, fetchReportsCallback);
    }

    const fetchReportsCallback = (data) => {
        if ("success" in data) {
            setReports(data["reports"]);
        }
    }

    const onClickofReview = (repId) => {
        setSelectedReport(repId);
    }

    const filterReports = (reports, tab, date) => {
        const today = new Date().toLocaleDateString('en-CA');
        const selectedDateString = date ? date.toLocaleDateString('en-CA') : '';
        if (tab === 'today') {
            return reports.filter(report => new Date(report.time).toLocaleDateString('en-CA') === today);
        } else if (tab === 'past' && date) {
            return reports.filter(report => new Date(report.time).toLocaleDateString('en-CA') === selectedDateString);
        } else if (tab === 'allPast') {
            return reports.filter(report => new Date(report.time).toLocaleDateString('en-CA') !== today);
        } else {
            return reports.filter(report => new Date(report.time).toLocaleDateString('en-CA') !== today);
        }
    }

    const getSectionedReports = (reports) => {
        console.log(reports)
        const invalidReports = reports.filter(report => !report.isValid && !report.isSolved);
        const unreviewed = reports.filter(report => report.isValid && !report.isReviewed);
        const reviewedNotSolved = reports.filter(report => report.isReviewed && !report.isSolved);
        const reviewedAndSolved = reports.filter(report => report.isReviewed && report.isSolved);
        return { invalidReports, unreviewed, reviewedNotSolved, reviewedAndSolved };
    }

    const filteredReports = filterReports(reports, selectedTab, selectedTab === 'past' ? selectedDate : null);
    const { invalidReports, unreviewed, reviewedNotSolved, reviewedAndSolved } = getSectionedReports(filteredReports);

    const paginate = (reports, page) => {
        const start = (page - 1) * recordsPerPage;
        const end = start + recordsPerPage;
        return reports.slice(start, end);
    }

    const paginatedReports = (section) => paginate(section, currentPage);

    const renderSection = (title, section) => {
        const rows = [];
        const sectionReports = paginatedReports(section);
        for (let i = 0; i < sectionReports.length; i += 2) {
            rows.push(sectionReports.slice(i, i + 2));
        }

        return (
            <div className="section">
                <h5>{title}</h5>
                {rows.map((row, rowIndex) => (
                    <Row key={rowIndex} className="mb-4">
                        {row.map((report, index) => (
                            <Col key={index} sm={6}>
                                <Card className={`report-card ${report.isReported ? 'reported' : ''}`}>
                                    <Card.Body className="d-flex flex-column">
                                            <Card.Title>Report ID: {report.id}</Card.Title>
                                            <Card.Text>
                                                Parking Lot: {report.parkingLot}<br />
                                                Spot: {report.spot}<br />
                                                Time: {new Date(report.time).toLocaleString()}<br />
                                                Plate: {report.plateNumber}<br />
                                                Status: {report.isReviewed ? (report.isSolved ? 'Solved' : 'Reviewed') : 'Pending'}
                                            </Card.Text>
                                        <Button 
                                            onClick={() => onClickofReview(report.id)} 
                                            variant="primary" 
                                            className="mt-auto align-self-start"
                                        >
                                           {!report.isValid && !report.isSolved ? 'Validate': !report.isReviewed ? 'Review' : !report.isSolved ? 'Solve' : 'Action'}
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                ))}
            </div>
        );
    }

    return (
        <Container fluid>
            <Row>
                <Col md={3}>
                    <SideBar username={userInfo.username} />
                </Col>
                <Col md={8}>
                    <div className="mt-4 pt-3 border-bottom d-flex justify-content-between align-items-center">
                        <div>
                            <h4>Manage Reports</h4>
                            <p>Review and update reported violations</p>
                        </div>
                    </div>
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={selectedTab}
                        onSelect={(k) => {
                            setSelectedTab(k);
                            setCurrentPage(1); // Reset to first page
                        }}
                        className="mt-4 mb-4"
                    >
                        <Tab eventKey="today" title="Today" />
                        <Tab eventKey="past" title="Past" />
                        <Tab eventKey="allPast" title="All Past" />
                    </Tabs>
                    {selectedTab === 'past' && (
                        <div className="calendar-container mb-4">
                            <Calendar
                                onChange={date => {
                                    setSelectedDate(date);
                                    setCurrentPage(1);  // Reset to first page
                                }}
                                value={selectedDate}
                            />
                        </div>
                    )}

                    {/* Section for Invalid Reports */}
                    {renderSection("Invalid Reports", invalidReports)}

                    {/* Sections for other reports */}
                    {renderSection("Pending Review", unreviewed)}
                    {renderSection("Reviewed, Not Solved", reviewedNotSolved)}
                    {renderSection("Reviewed and Solved", reviewedAndSolved)}

                    <div className="pagination mt-4 d-flex justify-content-center">
                        {currentPage > 1 && (
                            <Button variant="secondary" className="mr-2" onClick={() => setCurrentPage(currentPage - 1)}>
                                Previous
                            </Button>
                        )}
                        {currentPage < Math.ceil(filteredReports.length / recordsPerPage) && (
                            <Button variant="secondary" onClick={() => setCurrentPage(currentPage + 1)}>
                                Next
                            </Button>
                        )}
                    </div>
                    {selectedReport && (
                        <ReportReview 
                            reportId={selectedReport} 
                            onClose={() => setSelectedReport(null)}
                            onUpdate={() => {
                                fetchReports();
                                setSelectedReport(null);
                            }} 
                        />
                    )}
                </Col>
            </Row>
        </Container>
    );
}

export default ReportManager;
